import { ethers } from "ethers";
import React, { useEffect, useState, useMemo } from "react";
import Button from "../components/common/Button";
import Modal from "../components/common/Modal";
import Card from "../components/common/Card";
import Spinner from "../components/common/Spinner";
import StakingContractABI from "../contracts/StakingContract.json";
import fromExponential from "from-exponential";
import {
  formattedShort,
  formattedNumberTwo,
  formattedNumber,
  getFullDisplayBalance,
} from "../utils/formatNumber.js";
import AnimatedNumber from "animated-number-react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useParams } from "react-router-dom";
import { useAccount, useBalance } from "wagmi";
import ERC20 from "../contracts/ERC20.json";
import CONFIG from "../utils/config.js";
import { Web3 } from "web3";

import { readContract, writeContract, waitForTransaction } from "@wagmi/core";

const StakingPage = () => {
  const { address } = useAccount();
  const [stakeLoading, setStakeLoading] = useState(false);
  const [unstakeLoading, setUnstakeLoading] = useState(false);
  const [withdrawLoading, setWithdrawLoading] = useState(false);
  const [totalSupply, setTotalSupply] = useState();
  const [totalStaked, setTotalStaked] = useState();
  const [stakes, setStakes] = useState();
  const [minStake, setMinStake] = useState();
  const [stakingTax, setStakingTax] = useState();
  const [unstakingTax, setUnstakingTax] = useState();
  const [registrationTax, setRegistrationTax] = useState();
  const [referralRewards, setReferralRewards] = useState();
  const [referralCount, setReferralCount] = useState();
  const [dailyROI, setDailyROI] = useState();
  const [stakingRewards, setStakeRewards] = useState();
  const [minRegister, setMinRegister] = useState();
  const [totalRewards, setTotalRewards] = useState();
  const [registeredStatus, setRegisteredStaus] = useState();
  const [timeToUnstakeValue, setTimeToUnstakeValue] = useState();

  const [claimedRewardsValue, setClaimedRewardsValue] = useState();

  const [amount, setAmount] = useState();
  const [unstakeAmount, setUnstakeAmount] = useState();
  const [referrer, setReferrer] = useState();
  const [showModal, setShowModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [copied, setCopied] = useState(false);
  const { wallet } = useParams();

  const { data: tokenBalanceValue, refetch } = useBalance({
    address: address,
    token: CONFIG.tokenAddress,
  });
  const tokenBalance = useMemo(() => {
    return tokenBalanceValue ? tokenBalanceValue.value : 0;
  }, [tokenBalanceValue]);

  useEffect(() => {
    const init = async () => {
      try {
        if (!address) return;

        const totalSupplyValue = await readContract({
          address: CONFIG.tokenAddress,
          abi: ERC20,
          functionName: "totalSupply",
        });
        const timeToUnstake = await readContract({
          address: CONFIG.stakingContract,
          abi: StakingContractABI,
          functionName: "timeToUnstake",
          args: [address],
        });

        const claimedRewards = await readContract({
          address: CONFIG.stakingContract,
          abi: StakingContractABI,
          functionName: "claimedRewards",
          args: [address],
        });
        console.log("claimedRewards", claimedRewards);

        const totalStakedValue = await readContract({
          address: CONFIG.stakingContract,
          abi: StakingContractABI,
          functionName: "totalStaked",
        });
        const minStakeValue = await readContract({
          address: CONFIG.stakingContract,
          abi: StakingContractABI,
          functionName: "minimumStakeValue",
        });

        const stakingTaxRateValue = await readContract({
          address: CONFIG.stakingContract,
          abi: StakingContractABI,
          functionName: "stakingTaxRate",
        });

        const unstakingTaxRateValue = await readContract({
          address: CONFIG.stakingContract,
          abi: StakingContractABI,
          functionName: "unstakingTaxRate",
        });

        const registrationTaxValue = await readContract({
          address: CONFIG.stakingContract,
          abi: StakingContractABI,
          functionName: "registrationTax",
        });
        console.log("registerTax", registrationTaxValue);
        const referralRewardsValue = await readContract({
          address: CONFIG.stakingContract,
          abi: StakingContractABI,
          functionName: "referralRewards",
          args: [address],
        });
        console.log("referralRewardsValue", referralRewardsValue);

        const referralCountValue = await readContract({
          address: CONFIG.stakingContract,
          abi: StakingContractABI,
          functionName: "referralCount",
          args: [address],
        });
        console.log("referralCountValue", referralCountValue);

        const dailyROIValue = await readContract({
          address: CONFIG.stakingContract,
          abi: StakingContractABI,
          functionName: "dailyROI",
        });

        const statusValue = await readContract({
          address: CONFIG.stakingContract,
          abi: StakingContractABI,
          functionName: "registered",
          args: [address],
        });

        console.log("time", timeToUnstake);

        setClaimedRewardsValue(claimedRewards);

        setTimeToUnstakeValue(timeToUnstake);
        setTotalSupply(totalSupplyValue);
        setTotalStaked(Number(totalStakedValue));
        setMinStake(Number(minStakeValue));
        setStakingTax(Number(stakingTaxRateValue));
        setUnstakingTax(Number(unstakingTaxRateValue));
        setRegistrationTax(Number(registrationTaxValue));
        setReferralRewards(Number(referralRewardsValue));
        setReferralCount(Number(referralCountValue));
        setDailyROI(Number(dailyROIValue));
        setRegisteredStaus(Number(statusValue));
      } catch (err) {
        console.error(err);
      }
    };

    init();
  }, [address]);

  async function updateAll() {
    await refetch();
    await Promise.all([
      updateStakes(),
      updateTotalSupply(),
      updateTotalStaked(),
      stakeRewards(),
      minRegisteration(),
      totalReward(),
      updateReferrals(),
      updateStatus(),
    ]);
  }

  useEffect(() => {
    if (address) {
      updateAll();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address]);

  async function updateStakes() {
    if (!address) return;
    const stake = await readContract({
      address: CONFIG.stakingContract,
      abi: StakingContractABI,
      functionName: "stakes",
      args: [address],
    });
    setStakes(stake);
    return stake;
  }

  async function updateReferrals() {
    if (!address) return;
    const referralRewards = await readContract({
      address: CONFIG.stakingContract,
      abi: StakingContractABI,
      functionName: "referralRewards",
      args: [address],
    });
    const referralCount = await readContract({
      address: CONFIG.stakingContract,
      abi: StakingContractABI,
      functionName: "referralCount",
      args: [address],
    });
    setReferralRewards(referralRewards);
    setReferralCount(referralCount);
  }

  async function updateTotalSupply() {
    if (!address) return;
    const totalSupply = await readContract({
      address: CONFIG.tokenAddress,
      abi: ERC20,
      functionName: "totalSupply",
    });
    setTotalSupply(totalSupply);
    return totalSupply;
  }

  async function updateTotalStaked() {
    if (!address) return;
    const totalStaked = await readContract({
      address: CONFIG.stakingContract,
      abi: StakingContractABI,
      functionName: "totalStaked",
    });
    return totalStaked;
  }

  async function minRegisteration() {
    if (!address) return;
    const value = await readContract({
      address: CONFIG.stakingContract,
      abi: StakingContractABI,
      functionName: "minimumStakeValue",
    });
    const tax = await readContract({
      address: CONFIG.stakingContract,
      abi: StakingContractABI,
      functionName: "registrationTax",
    });
    console.log("tax", tax);
    console.log("value", value);
    const sum = Number(tax) + Number(value);
    setMinRegister(sum);
    return sum;
  }

  async function stakeRewards() {
    if (!address) return;
    const rewards = await readContract({
      address: CONFIG.stakingContract,
      abi: StakingContractABI,
      functionName: "stakeRewards",
      args: [address],
    });
    const owing = await readContract({
      address: CONFIG.stakingContract,
      abi: StakingContractABI,
      functionName: "calculateEarnings",
      args: [address],
    });
    const sum = Number(rewards) + Number(owing);
    setStakeRewards(sum);
    return sum;
  }

  async function totalReward() {
    if (!address) return;
    const owing = await readContract({
      address: CONFIG.stakingContract,
      abi: StakingContractABI,
      functionName: "calculateEarnings",
      args: [address],
    });
    const recorded = await readContract({
      address: CONFIG.stakingContract,
      abi: StakingContractABI,
      functionName: "stakeRewards",
      args: [address],
    });
    const referral = await readContract({
      address: CONFIG.stakingContract,
      abi: StakingContractABI,
      functionName: "referralRewards",
      args: [address],
    });
    const sum = Number(owing) + Number(referral) + Number(recorded);
    setTotalRewards(sum);
    return sum;
  }

  async function updateStatus() {
    if (!address) return;
    const status = await readContract({
      address: CONFIG.stakingContract,
      abi: StakingContractABI,
      functionName: "registered",
      args: [address],
    });
    setRegisteredStaus(status);
  }

  async function registerAndStake() {
    if (Number(tokenBalance) === 0 || !amount || Number(amount) === 0) {
      setErrorMessage(
        Number(tokenBalance) === 0
          ? `You don't have any ${CONFIG.tokenSymbol} yet!`
          : "Please provide the amount needed to stake!"
      );
      setShowModal(true);
      return;
    }

    if (Number(tokenBalance) < Number(minRegister)) {
      setErrorMessage(`You don't have enough ${CONFIG.tokenSymbol} to stake`);
      setShowModal(true);
      return;
    }
    if (
      Number(
        fromExponential(
          ethers.parseUnits(Number(amount).toString(), CONFIG.tokenDecimals)
        )
      ) < Number(minRegister)
    ) {
      setErrorMessage(
        `Minimum staking amount is ${ethers.formatUnits(
          Number(minRegister),
          CONFIG.tokenDecimals
        )} ${CONFIG.tokenSymbol}`
      );
      setShowModal(true);
      return;
    }
    setStakeLoading(true);

    const arg = fromExponential(
      ethers.parseUnits(Number(amount).toString(), CONFIG.tokenDecimals)
    );

    try {
      let ref = referrer;
      const { hash } = await writeContract({
        address: CONFIG.tokenAddress,
        abi: ERC20,
        functionName: "approve",
        args: [CONFIG.stakingContract, arg],
      });
      // Wait for the approval transaction to be confirmed
      await waitForTransaction({ hash });

      if (!Web3.utils.isAddress(ref)) {
        ref = "0x0000000000000000000000000000000000000000";
      }

      const { hash: stakeTX } = await writeContract({
        address: CONFIG.stakingContract,
        abi: StakingContractABI,
        functionName: "registerAndStake",
        args: [arg, ref],
      });
      await waitForTransaction({ hash: stakeTX });

      await updateAll();
    } catch (err) {
      if (err.code !== 4001) {
        setErrorMessage(err.message);
        setShowModal(true);
      }
    }

    setStakeLoading(false);
  }

  async function stake() {
    if (Number(tokenBalance) === 0 || !amount || Number(amount) === 0) {
      setErrorMessage(
        Number(tokenBalance) === 0
          ? `You don't have any ${CONFIG.tokenSymbol} yet!`
          : "Please provide the amount needed to stake!"
      );
      setShowModal(true);
      return;
    }
    if (Number(tokenBalance) < Number(minRegister)) {
      setErrorMessage(`You don't have enough ${CONFIG.tokenSymbol} to stake`);
      setShowModal(true);
      return;
    }
    if (
      Number(
        fromExponential(
          ethers.parseUnits(Number(amount).toString(), CONFIG.tokenDecimals)
        )
      ) < Number(minRegister)
    ) {
      setErrorMessage(
        `Minimum staking amount is ${ethers.formatUnits(
          BigInt(minRegister),
          CONFIG.tokenDecimals
        )} ${CONFIG.tokenSymbol}`
      );
      setShowModal(true);
      return;
    }
    setStakeLoading(true);

    const arg = fromExponential(
      ethers.parseUnits(Number(amount).toString(), CONFIG.tokenDecimals)
    );

    try {
      const { hash } = await writeContract({
        address: CONFIG.tokenAddress,
        abi: ERC20,
        functionName: "approve",
        args: [CONFIG.stakingContract, arg],
      });
      // Wait for the approval transaction to be confirmed
      await waitForTransaction({ hash });

      const { hash: stakeTX } = await writeContract({
        address: CONFIG.stakingContract,
        abi: StakingContractABI,
        functionName: "stake",
        args: [arg],
      });
      await waitForTransaction({ hash: stakeTX });

      await updateAll();
    } catch (err) {
      if (err.code !== 4001) {
        setErrorMessage(err.message);
        setShowModal(true);
      }
    }

    setStakeLoading(false);
  }

  async function unstake() {
    if (Number(stakes) === 0 || !unstakeAmount || Number(unstakeAmount) === 0) {
      setErrorMessage(
        Number(stakes) === 0
          ? `You don't have any ${CONFIG.tokenSymbol} yet!`
          : "Please provide the amount needed to unstake!"
      );
      setShowModal(true);
      return;
    }

    setUnstakeLoading(true);

    const arg = fromExponential(
      ethers.parseUnits(Number(unstakeAmount).toString(), CONFIG.tokenDecimals)
    );

    try {
      const { hash } = await writeContract({
        address: CONFIG.stakingContract,
        abi: StakingContractABI,
        functionName: "unstake",
        args: [arg],
      });
      await waitForTransaction({ hash });
      await updateAll();
    } catch (err) {
      console.log(err);
      if (err.code !== 4001) {
        setErrorMessage(err.message);
        setShowModal(true);
      }
    }

    setUnstakeLoading(false);
  }

  async function withdrawEarnings() {
    if (Number(totalRewards) === 0) {
      setErrorMessage("No earnings available to withdraw!");
      setShowModal(true);
      return;
    }

    setWithdrawLoading(true);

    try {
      const { hash: withdrawEarnings } = await writeContract({
        address: CONFIG.stakingContract,
        abi: StakingContractABI,
        functionName: "withdrawEarnings",
      });

      // Wait for transaction confirmation
      await waitForTransaction({ hash: withdrawEarnings });

      // Optionally, delay before refreshing state
      await new Promise((resolve) => setTimeout(resolve, 2000));

      await updateAll(); // Refresh frontend state
    } catch (err) {
      if (err.code !== 4001) {
        // Ignore user rejection errors
        console.error("Withdrawal Error:", err); // Log full error details
        setErrorMessage(
          err.reason || err.message || "An error occurred during withdrawal."
        );
        setShowModal(true);
      }
    }

    setWithdrawLoading(false);
  }

  useEffect(() => {
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  }, [copied]);

  useEffect(() => {
    setReferrer(wallet);
  }, [wallet]);

  return (
    <div className='w-full overflow-hidden'>
      {showModal && (
        <Modal
          title=''
          onClose={() => setShowModal(false)}
          className='bg-black text-white rounded-lg shadow-lg'
        >
          <div className='p-5'>
            <div className='text-lg md:text-3xl mb-4 font-bold text-red-500 animate-pulse'>
              ⚠️ Error! Please retry...
              <p className='text-sm text-vyellow mt-4'>
                {" "}
                When staking for the first time, it may take up to 24 hours for
                the data to reflect, after which rewards can be claimed.
              </p>
            </div>

            <div className='text-sm mb-4 border-l-4 border-red-500 pl-4'>
              {errorMessage}
            </div>

            <div className='flex flex-row justify-center mt-5'>
              <Button
                onClick={() => setShowModal(false)}
                className='bg-red-500 hover:bg-red-600 text-white py-2 px-4 rounded-full transition duration-300 ease-in-out transform hover:scale-105'
              >
                Close
              </Button>
            </div>
          </div>
        </Modal>
      )}
      <div className='relative z-20 w-full top-0'>
        {/* <img
          src="/images/nosiy.png"
          alt=""
          className="absolute z-10 top-noisy"
        />
    */}
      </div>

      <div className='relative w-full z-30'>
        <div className='  md:my-8 my-5  mx-auto   '>
          <div className='grid w-full grid-cols-1 gap-8 md:grid-cols-2'>
            <div className='flex gap-2 justify-between'>
              <div className='w-full p-8  backdrop-blur-lg flex flex-col gap-16 border-solid border-opacity-20 border-vyellow rounded-2xl shadow-lg  shadow-vyellow_1 border-1 border  bg-vgray_3   bg-opacity-10  '>
                <div className='flex justify-center flex-col gap-8'>
                  <p className='text-sm text-vyellow'>
                    Total ${CONFIG.tokenSymbol} Tokens Staked
                  </p>
                  <div className='flex flex-col  text-white'>
                    <div className='text-center'>
                      <span className='text-vyellow font-bold text-2xl md:text-4xl'>
                        <AnimatedNumber
                          value={
                            totalStaked
                              ? getFullDisplayBalance(
                                  totalStaked,
                                  CONFIG.tokenDecimals
                                )
                              : "0.00"
                          }
                          duration='2000'
                          formatValue={(value) => `${formattedShort(value)}`}
                        ></AnimatedNumber>
                      </span>
                      <span className='text-white font-bold text-2xl md:text-4xl ml-2'>
                        {CONFIG.tokenSymbol}
                      </span>
                    </div>
                    {/* <div className="text-center text-3xl 	">
                    Total Value: <AnimatedNumber
                      value={(totalStaked) ? (Number(totalStaked) * 100.0) /
                        Number(totalSupply) : '0.00%'}
                      duration="2000"
                      formatValue={value => `${formattedShort(value)}`}
                    >
                    </AnimatedNumber>$

                  </div> */}
                    <div className='text-center mt-2 text-md	'>
                      {
                        <AnimatedNumber
                          value={
                            totalStaked
                              ? Number(
                                  (Number(totalStaked) * 100.0) /
                                    Number(totalSupply)
                                ).toFixed(11)
                              : "0.00%"
                          }
                          duration='2000'
                          formatValue={(value) => `${formattedShort(value)}`}
                        ></AnimatedNumber>
                      }
                      % of total supply
                    </div>
                  </div>
                </div>
                <ul className=' text-lg grid md:grid-cols-4 grid-cols-1 w-full gap-4'>
                  <li className='text-sm flex  flex-col gap-2 items-center text-vyellow'>
                    Registration Fee
                    <span className='text-white text-2xl'>
                      <AnimatedNumber
                        value={registrationTax ? registrationTax : "0.00"}
                        duration='2000'
                        formatValue={(value) => `${formattedNumberTwo(value)}`}
                      ></AnimatedNumber>{" "}
                    </span>{" "}
                    <span className='text-vyellow text-xl'>
                      ${CONFIG.tokenSymbol}
                    </span>
                  </li>
                  <li className='text-sm flex  flex-col gap-2 items-center text-vyellow'>
                    Staking Fee
                    <span className='text-white text-2xl'>
                      <AnimatedNumber
                        value={stakingTax ? Number(stakingTax) / 10 : "0.00%"}
                        duration='2000'
                        formatValue={(value) => `${formattedShort(value)}`}
                      ></AnimatedNumber>
                      %
                    </span>
                  </li>
                  <li className='text-sm flex  flex-col gap-2 text-left items-center text-vyellow'>
                    Unstaking Fee{"  "}
                    <span className='text-white text-2xl'>
                      <AnimatedNumber
                        value={
                          unstakingTax ? Number(unstakingTax) / 10 : "0.00%"
                        }
                        duration='2000'
                        formatValue={(value) => `${formattedShort(value)}`}
                      ></AnimatedNumber>
                      %
                    </span>
                  </li>
                  <li className='text-sm flex  flex-col gap-2 items-center text-vyellow'>
                    Minimum Stake
                    <span className='text-white text-2xl'>
                      <AnimatedNumber
                        value={minStake ? minStake : "0.00"}
                        duration='2000'
                        formatValue={(value) => `${formattedNumberTwo(value)}`}
                      ></AnimatedNumber>

                      {/*     <AnimatedNumber
                        value={
                          minStake
                            ? ethers.formatUnits(
                                BigInt(minStake),
                                CONFIG.tokenDecimals
                              )
                            : "0.00"
                        }
                        duration='2000'
                        
                        formatValue={(value) => `${formattedNumberTwo(value)}`}
                      ></AnimatedNumber>*/}
                    </span>
                    <span className='text-vyellow text-xl'>
                      ${CONFIG.tokenSymbol}
                    </span>
                  </li>{" "}
                </ul>{" "}
              </div>
            </div>
            <div className='flex gap-2 justify-between'>
              <div className='w-full p-8 backdrop-blur-lg flex flex-col gap-3 border-solid border-opacity-20 border-vyellow rounded-2xl shadow-lg shadow-vyellow_1 border-1 border  bg-vgray_3   bg-opacity-10  '>
                <div className='flex justify-center flex-col gap-8'>
                  <p className='text-sm text-vyellow'>Your Earnings</p>
                  <div className='flex flex-col gap-8  text-white'>
                    <div className='text-center'>
                      <span className='text-vyellow font-bold text-2xl md:text-4xl'>
                        <AnimatedNumber
                          value={
                            totalRewards
                              ? ethers.formatUnits(
                                  BigInt(totalRewards),
                                  CONFIG.tokenDecimals
                                )
                              : "0.00"
                          }
                          duration='2000'
                          formatValue={(value) =>
                            `${formattedNumberTwo(value)}`
                          }
                        ></AnimatedNumber>
                      </span>
                      <span className='text-white font-bold text-2xl md:text-4xl ml-2'>
                        {CONFIG.tokenSymbol}
                      </span>
                    </div>
                    <div className='flex flex-col gap-5 md:mx-0 mx-auto md:flex-row relative justify-center'>
                      {timeToUnstakeValue !== 0n && (
                        <div className='relative flex flex-row gap-5 items-center text-md font-bold md:absolute left-0 md:left-5'>
                          <span className='text-white font-normal text-xs'>
                            Time To Unstake:
                          </span>{" "}
                          {timeToUnstakeValue !== undefined &&
                          timeToUnstakeValue !== null
                            ? `${BigInt(timeToUnstakeValue) / BigInt(3600)}h:
       ${(BigInt(timeToUnstakeValue) % BigInt(3600)) / BigInt(60)}m:
       ${BigInt(timeToUnstakeValue) % BigInt(60)}s`
                            : "Loading..."}
                        </div>
                      )}

                      <button
                        className={`flex flex-row items-center mx-auto   py-2 border-1 border  border-vyellow rounded-md py-5 h-8  justify-center w-32  ${
                          totalRewards
                            ? "hover:text-vyellow bg-vyellow text-black hover:bg-vgray "
                            : " bg-vgray disabled"
                        }  `}
                        onClick={() => withdrawEarnings()}
                      >
                        {withdrawLoading ? (
                          <Spinner size={30} />
                        ) : (
                          <>
                            <span className='font-bold'>CLAIM</span>{" "}
                          </>
                        )}
                      </button>
                    </div>
                    <ul className=' text-lg grid md:grid-cols-5 grid-cols-1 w-full gap-4'>
                      <li className='text-sm flex  flex-col gap-2 items-center text-vyellow'>
                        Staking Reward
                        <span className='text-white text-2xl'>
                          <AnimatedNumber
                            value={
                              stakingRewards
                                ? ethers.formatUnits(
                                    BigInt(stakingRewards),
                                    CONFIG.tokenDecimals
                                  )
                                : "0.00"
                            }
                            duration='2000'
                            formatValue={(value) =>
                              `${formattedNumberTwo(value)}`
                            }
                          ></AnimatedNumber>{" "}
                        </span>
                        <span className='text-vyellow text-xl'>
                          ${CONFIG.tokenSymbol}
                        </span>
                      </li>
                      <li className='text-sm flex  flex-col gap-2 items-center text-vyellow'>
                        Total Claimed
                        <span className='text-white text-2xl'>
                          <AnimatedNumber
                            value={
                              claimedRewardsValue
                                ? ethers.formatUnits(
                                    BigInt(claimedRewardsValue),
                                    CONFIG.tokenDecimals
                                  )
                                : "0.00"
                            }
                            duration='2000'
                            formatValue={(value) =>
                              `${formattedNumberTwo(value)}`
                            }
                          ></AnimatedNumber>{" "}
                        </span>
                        <span className='text-vyellow text-xl'>
                          ${CONFIG.tokenSymbol}
                        </span>
                      </li>
                      <li className='text-sm flex  flex-col gap-2 items-center text-vyellow'>
                        Daily Return
                        <span className='text-white text-2xl'>
                          <AnimatedNumber
                            value={dailyROI ? Number(dailyROI) / 100 : "0.00%"}
                            duration='2000'
                            formatValue={(value) =>
                              `${formattedNumberTwo(value)}`
                            }
                          ></AnimatedNumber>
                          %
                        </span>
                      </li>
                      <li className='text-sm flex  flex-col gap-2 items-center text-vyellow'>
                        Referral Reward
                        <span className='text-white text-2xl'>
                          <AnimatedNumber
                            value={
                              referralRewards
                                ? ethers.formatUnits(
                                    BigInt(referralRewards),
                                    CONFIG.tokenDecimals
                                  )
                                : "0.00"
                            }
                            duration='2000'
                            formatValue={(value) =>
                              `${formattedNumberTwo(value)}`
                            }
                          ></AnimatedNumber>{" "}
                        </span>
                        <span className='text-vyellow text-xl'>
                          ${CONFIG.tokenSymbol}
                        </span>
                      </li>
                      <li className='text-sm flex  flex-col gap-2 items-center text-vyellow'>
                        Referral Count
                        <span className='text-white text-2xl'>
                          {Number(referralCount)}
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className='w-full p-8  backdrop-blur-lg flex flex-col gap-16 border-solid border-opacity-20 border-vyellow rounded-2xl shadow-lg shadow-vyellow_1 border-1 border  bg-vgray_3   bg-opacity-10  '>
              {!registeredStatus ? (
                <div className='flex flex-col gap-8'>
                  {" "}
                  <p className='text-sm text-vyellow'>Staking</p>
                  <div className='flex flex-col gap-16  pt-8 px-2'>
                    <div className='flex flex-col md:flex-row justify-between'>
                      <div className='text-center flex gap-2 items-center justify-around flex-col pb-4'>
                        <span className='text-sm text-vyellow'>
                          Minimum amount needed{" "}
                        </span>
                        <span className='text-white text-2xl'>
                          <AnimatedNumber
                            value={minRegister ? minRegister : "0.00"}
                            duration='2000'
                            formatValue={(value) =>
                              `${formattedNumberTwo(value)}`
                            }
                          ></AnimatedNumber>
                          {/*<AnimatedNumber
                            value={
                              minRegister
                                ? ethers.formatUnits(
                                    BigInt(minRegister),
                                    CONFIG.tokenDecimals
                                  )
                                : "0.00"
                            }
                            duration='2000'
                            formatValue={(value) =>
                              `${formattedNumberTwo(value)}`
                            }
                          ></AnimatedNumber>*/}
                          <span className='text-white text-2xl ml-2'>
                            {CONFIG.tokenSymbol}
                          </span>
                        </span>
                      </div>
                      <div className='text-center flex gap-2 items-center justify-around flex-col pb-4'>
                        <span className='text-sm text-vyellow'>
                          Available amount{" "}
                        </span>
                        <span className='text-white text-2xl'>
                          <AnimatedNumber
                            value={
                              tokenBalance
                                ? ethers.formatUnits(
                                    tokenBalance,
                                    CONFIG.tokenDecimals
                                  )
                                : "0.00"
                            }
                            duration='2000'
                            formatValue={(value) =>
                              `${formattedNumberTwo(value)}`
                            }
                          ></AnimatedNumber>{" "}
                          <span className='text-white text-2xl ml-2'>
                            {CONFIG.tokenSymbol}
                          </span>
                        </span>
                      </div>
                    </div>
                    <div className=' flex gap-16 flex-col'>
                      <div className='relative w-full'>
                        <input
                          type='number'
                          placeholder={`${CONFIG.tokenSymbol} To Stake`}
                          value={amount}
                          onChange={(e) => setAmount(e.target.value)}
                          className='flex h-16 text-xl w-full focus:bg-vgray border border-[1px] border-opacity-20 border-vyellow selection:bg-black active:bg-black   rounded-md  bg-black px-3 py-2   file:border-0 file:bg-vyellow file:text-sm file:font-medium placeholder:text-yellow-100 placeholder:text-sm focus-visible:outline-none focus  disabled:cursor-not-allowed disabled:opacity-50'
                        />
                        <button
                          className='text-black mt-3 px-4 absolute top-0 right-0 cursor-pointer font-bold rounded-md border-2  text-md border-vyellow bg-vyellow p-1 mr-2 ml-1 flex justify-between items-center hover:bg-vgray hover:text-vyellow '
                          onClick={() =>
                            setAmount(
                              getFullDisplayBalance(
                                tokenBalance,
                                CONFIG.tokenDecimals
                              )
                            )
                          }
                        >
                          Max
                        </button>
                      </div>

                      <button
                        onClick={() => registerAndStake()}
                        className='flex  hover:text-vyellow bg-vyellow text-black hover:bg-vgray  py-2 border-1 border border-vyellow flex-row items-center w-48  font-bold mx-auto   border border-[1px]   rounded-lg    sm:text-lg justify-center '
                      >
                        {stakeLoading ? (
                          <Spinner size={30} color='#fff' />
                        ) : (
                          <>
                            <span className='text-xl md:text-2xl'>STAKE</span>{" "}
                          </>
                        )}
                      </button>
                    </div>
                  </div>
                  <div>
                    {" "}
                    <div className='text-sm text-vyellow my-8'>
                      Referring a wallet?
                    </div>
                    <div>
                      {" "}
                      <input
                        placeholder='Referrer Address'
                        value={referrer}
                        onChange={(e) => setReferrer(e.target.value)}
                        className='flex h-16 text-xl w-full focus:bg-vgray border border-[1px] border-opacity-20 border-vyellow selection:bg-black active:bg-black   rounded-md  bg-black px-3 py-2   file:border-0 file:bg-vyellow file:text-sm file:font-medium placeholder:text-yellow-100 placeholder:text-sm focus-visible:outline-none focus  disabled:cursor-not-allowed disabled:opacity-50'
                      />
                    </div>
                    <div className='text-center p-2 md:p-5'>
                      <span className='text-xs text-white'>
                        If you have a wallet to referr, please type it here.
                        It's important that the mentioned wallet to have some
                        &nbsp;
                        {CONFIG.tokenSymbol} Tokens already staked otherwise, it
                        will not work.
                      </span>
                    </div>
                  </div>
                </div>
              ) : (
                <div className='flex flex-col gap-8'>
                  {" "}
                  <p className='text-sm text-vyellow'>Staking</p>
                  <div className='flex flex-col pt-8 px-2'>
                    <div className='flex flex-col md:flex-row justify-around'>
                      <div className='text-center flex gap-2 items-center justify-around flex-col pb-4'>
                        <span className='text-sm text-vyellow'>
                          Minimum amount needed:{" "}
                        </span>
                        <span className='text-white text-2xl '>
                          <AnimatedNumber
                            value={minRegister ? minRegister : "0.00"}
                            duration='2000'
                            formatValue={(value) =>
                              `${formattedNumberTwo(value)}`
                            }
                          ></AnimatedNumber>

                          {/*<AnimatedNumber
                            value={
                              minRegister
                                ? ethers.formatUnits(
                                    BigInt(minRegister),
                                    CONFIG.tokenDecimals
                                  )
                                : "0.00"
                            }
                            duration='2000'
                            formatValue={(value) => `${formattedNumber(value)}`}
                          ></AnimatedNumber>*/}
                        </span>
                        <span className='text-vyellow text-xl ml-2'>
                          ${CONFIG.tokenSymbol}
                        </span>
                      </div>
                      <div className='text-center flex gap-2 items-center justify-around flex-col pb-4'>
                        <span className='text-sm text-vyellow'>
                          Available amount:{" "}
                        </span>
                        <span className='text-white text-2xl '>
                          <AnimatedNumber
                            value={
                              tokenBalance
                                ? ethers.formatUnits(
                                    tokenBalance,
                                    CONFIG.tokenDecimals
                                  )
                                : "0.00"
                            }
                            duration='2000'
                            formatValue={(value) =>
                              `${formattedNumberTwo(value)}`
                            }
                          ></AnimatedNumber>
                        </span>
                        <span className='text-vyellow text-xl ml-2'>
                          ${CONFIG.tokenSymbol}
                        </span>
                      </div>
                    </div>
                    <div className=' flex gap-8 flex-col'>
                      <div className='relative w-full'>
                        {" "}
                        <input
                          type='number'
                          placeholder={`${CONFIG.tokenSymbol} To Stake`}
                          value={amount}
                          onChange={(e) => setAmount(e.target.value)}
                          className='flex h-16 text-xl w-full focus:bg-vgray border border-[1px] border-opacity-20 border-vyellow selection:bg-black active:bg-black   rounded-md  bg-black px-3 py-2   file:border-0 file:bg-vyellow file:text-sm file:font-medium placeholder:text-yellow-100 placeholder:text-sm focus-visible:outline-none focus  disabled:cursor-not-allowed disabled:opacity-50'
                        />
                        <button
                          className='text-black mt-3 px-4 absolute top-0 right-0 cursor-pointer font-bold rounded-md border-2 text-md border-vyellow bg-vyellow p-1 mr-2 ml-1 flex justify-between items-center hover:bg-vgray hover:text-vyellow '
                          onClick={() =>
                            setAmount(
                              ethers.formatUnits(
                                tokenBalance,
                                CONFIG.tokenDecimals
                              )
                            )
                          }
                        >
                          Max
                        </button>
                      </div>
                      <button
                        onClick={() => stake()}
                        className='flex flex-row items-center w-48  font-bold mx-auto   rounded-lg     py-2  hover:text-vyellow bg-vyellow text-black hover:bg-vgray  py-2 border-1 border border-vyellow  sm:text-lg justify-center'
                      >
                        {stakeLoading ? (
                          <Spinner size={30} />
                        ) : (
                          <>
                            <span className='text-xl md:text-2xl'>STAKE</span>{" "}
                          </>
                        )}
                      </button>
                      <div className='flex flex-col gap-8 mt-12 text-md text-left text-white'>
                        <p className='text-white'>
                          Please note that once you have staked your tokens, you
                          will no longer be eligible to utilize the referral
                          link.{" "}
                        </p>
                        <p>
                          To unstake your tokens, you must wait for a 24-hour
                          period from the time of your most recent stake.
                        </p>
                        <p>
                          Additionally, any referral rewards earned will only
                          become claimable after a 24-hour period has elapsed
                          from the time they were earned.
                        </p>{" "}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className='flex flex-col  gap-8'>
              <div className='w-full p-8  backdrop-blur-lg flex flex-col gap-16 border-solid border-vyellow border-opacity-20 rounded-2xl shadow-lg shadow-vyellow_1 border-1 border  bg-vgray_3   bg-opacity-10  '>
                <div className='flex justify-center flex-col gap-8'>
                  <p className='text-sm text-vyellow'>Unstaking</p>
                  <div className='flex flex-col gap-8  text-white'>
                    <div className='flex md:flex-row flex-col gap-2 justify-between  my-auto'>
                      <p className='text-vyellow my-auto  text-lg'>
                        Available to Unstake{" "}
                      </p>
                      <p className='text-vyellow font-bold text-lg md:text-2xl'>
                        <AnimatedNumber
                          value={
                            stakes
                              ? ethers.formatUnits(
                                  BigInt(stakes),
                                  CONFIG.tokenDecimals
                                )
                              : "0.00"
                          }
                          duration='2000'
                          formatValue={(value) =>
                            `${formattedNumberTwo(value)}`
                          }
                        ></AnimatedNumber>{" "}
                        <span className='text-white text-lg md:text-2xl ml-2'>
                          {CONFIG.tokenSymbol}
                        </span>
                      </p>
                    </div>
                  </div>
                  <div className=' flex gap-8 flex-col'>
                    <div className='relative w-full'>
                      <input
                        type='number'
                        placeholder={`${CONFIG.tokenSymbol} To Unstake`}
                        value={unstakeAmount}
                        onChange={(e) => setUnstakeAmount(e.target.value)}
                        className='flex h-16 text-xl w-full focus:bg-vgray border border-[1px] border-opacity-20 border-vyellow selection:bg-black active:bg-black   rounded-md  bg-black px-3 py-2   file:border-0 file:bg-vyellow file:text-sm file:font-medium placeholder:text-yellow-100 placeholder:text-sm focus-visible:outline-none focus  disabled:cursor-not-allowed disabled:opacity-50'
                      />
                      <button
                        className='t mt-3 px-4 absolute top-0 right-0 cursor-pointer font-bold rounded-md border-2 text-md  p-1 mr-2 ml-1 flex justify-between items-center text-black hover:bg-vgray hover:text-vyellow border-vyellow bg-vyellow'
                        onClick={() =>
                          setUnstakeAmount(
                            ethers.formatUnits(
                              BigInt(stakes),
                              CONFIG.tokenDecimals
                            )
                          )
                        }
                      >
                        Max
                      </button>
                    </div>
                    <button
                      onClick={() => unstake()}
                      className='flex flex-row font-bold rounded-lg py-2 mx-auto items-center text-xs sm:text-lg w-48 justify-center hover:text-vyellow bg-vyellow text-black hover:bg-vgray  py-2 border-1 border border-vyellow'
                    >
                      {unstakeLoading ? (
                        <Spinner size={30} />
                      ) : (
                        <>
                          <span className='w-24  text-center text-xl'>
                            UNSTAKE
                          </span>
                        </>
                      )}
                    </button>
                  </div>
                </div>
              </div>
              <div className='w-full h-full p-8 backdrop-blur-lg flex flex-col gap-16 border-solid border-opacity-20 border-vyellow rounded-2xl shadow-lg shadow-vyellow_1 border-1 border  bg-vyellow/5   bg-opacity-10 '>
                <div className='flex justify-center flex-col gap-8'>
                  <p className='text-sm text-white font-bold'>
                    Your Referral Link
                  </p>
                  <div className='flex flex-col gap-8 text-sm text-vyellow'>
                    You will be eligible to earn EXTRA rewards by referring
                    other investors.
                    <p className='text-white text-md'>
                      You will earn{" "}
                      <span className='font-bold text-vyellow text-lg'>
                        {stakingTax / 10}%
                      </span>{" "}
                      out of the amount that the new investor stakes <br></br>
                      (after excluding the Registration Tax)
                    </p>
                    <div className='relative md:flex-row flex-col w-full'>
                      {" "}
                      <input
                        type='text'
                        readOnly
                        value={"https://ethdailystaking.versx.io/referral/".concat(
                          address
                        )}
                        onChange={(e) => setUnstakeAmount(e.target.value)}
                        className=' h-16 text-xs w-full focus:bg-vgray border border-[1px] border-vyellow selection:bg-black active:bg-black   rounded-md  bg-black px-3 py-2   file:border-0 file:bg-vyellow file:text-sm file:font-medium placeholder:text-yellow-100 placeholder:text-sm focus-visible:outline-none focus  disabled:cursor-not-allowed disabled:opacity-50'
                      />
                      <CopyToClipboard
                        onCopy={() => setCopied(true)}
                        text={"https://ethdailystaking.versx.io/referral/".concat(
                          address
                        )}
                      >
                        <button className='text-black mt-3 px-4 relative w-full md:w-auto  text-center md:absolute top-1 md:right-0 cursor-pointer font-bold rounded-md border-2 text-md border-vyellow bg-vyellow p-1 md:mr-2 md:ml-1 flex justify-between items-center hover:bg-vgray hover:text-vyellow '>
                          {copied ? (
                            <span className='w-full md:w-auto'>✅ COPIED</span>
                          ) : (
                            <>
                              <span className='w-full md:w-16'>COPY</span>
                            </>
                          )}
                        </button>
                      </CopyToClipboard>
                    </div>
                    <div className='text-center'>
                      <span className='text-md text-white'>
                        The new investor has to enter your Wallet Address that
                        you've staked {CONFIG.tokenSymbol} with when he stakes.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StakingPage;
